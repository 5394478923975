<form
  *ngIf="isReady"
  [formGroup]="form">
  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- Шаг 1: Ввод данных запроса поиска товара. -->
  <div *ngIf="step === 1" class="flex flex-col gap-4">
    <!-- Заголовок и описание. -->
    <div class="w-full pt-[28px]">
      <div class="w-full">
        <h2 class="w-full text-[28px] font-bold text-center">Поиск товаров</h2>
      </div>
    </div>
    <!-- /Заголовок и описание. -->

    <!-- Подсказка над полями ввода данных. -->
    <div class="w-full">
      <div class="w-full">
        <h3 class="w-full text-[16px] leading-6">Пожалуйста, укажите в свободной форме описание тора, который вы ищите,
          мы постараемся вам помочь.<br><br>
          Если есть фотографии товара, обязательно прикрепите их, это поможет более точно найти товар.</h3>
      </div>
    </div>
    <!-- /Подсказка над полями ввода данных. -->

    <!-- Поле ввода описания искомого товара. -->
    <div>
      <fieldset>
        <textarea
          name="text"
          formControlName="text"
          rows="5"
          textareaContentHeight
          [placeholder]="'Введите описание искомого товара.'"
          class="input_textarea"></textarea>
        <div
          *ngIf="isFieldInvalid('text')"
          class="flex flex-col
           text-[12px] text-bbRedRegular text-left whitespace-nowrap">
          <p>Обязательно введите описание искомого товара.</p>
          <p>Чтобы помочь вам, нам необходимо точно понять</p>
          <p>что именно вы ищите.</p>
        </div>
      </fieldset>
    </div>
    <!-- /Поле ввода описания искомого товара. -->

    <!-- Блок отображения прикреплённых файлов. -->
    <div
      class="w-full relative flex flex-row flex-wrap">
      <div
        *ngFor="let fileId of files; index as n"
        class="w-[63px] h-[63px] object-contain relative">
        <img
          (click)="clickFileDelete(n)"
          [loaderParams]="{api: true}"
          ngSrc="{{ fileId }}"
          fill
          disableOptimizedSrcset
          alt="Загруженное изображение"
          class="m-1 p-1 rounded-[12px]">
      </div>
    </div>
    <!-- /Блок отображения прикреплённых файлов. -->

    <!-- Кнопка выбора и прикрепления файла. -->
    <input #fileInput type="file" (change)="onFileSelected($event)" class="hidden">
    <button
      (click)="onButtonFile($event)"
      class="button_select_file flex flex-row gap-[16px]">
      <div class="w-full text-center">Добавить фото</div>
    </button>
    <!-- /Кнопка выбора и прикрепления файла. -->
  </div>
  <!-- /Шаг 1: Ввод данных запроса поиска товара. -->

  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- Шаг 2: Отображение сообщения о том что запрос был отправлен. -->
  <div *ngIf="step === 2" class="flex flex-col gap-4">
    <!-- Сообщение об успешной отправки данных на сервер. -->
    <div class="w-full pt-[28px] text-[18px]">
      <p class="w-full">
        Мы получили ваш запрос, оператор свяжется с вами в ближайшее время.
      </p>
      <p>Спасибо.</p>
    </div>
    <!-- /Сообщение об успешной отправки данных на сервер. -->
  </div>
  <!-- /Шаг 2: Отображение сообщения о том что запрос был отправлен. -->
</form>

<!-- Страничка с ошибкой аутентификации пользователя телеграм. -->
<app-telegram-unknown-user *ngIf="!isReady && isUnassigned"></app-telegram-unknown-user>
<!-- /Страничка с ошибкой аутентификации пользователя телеграм. -->
