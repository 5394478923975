import { ImageLoaderConfig } from "@angular/common";

import { IAppConfig } from "../../app.config";
import { IApiImgFileConfig } from "./img-file-from-api.types";


export class ImgFileFromApi {
  protected static apiImgFileComponent: ImgFileFromApi;
  protected static appConfig: IAppConfig;

  /**
   * Конструктор.
   * @param appConfig - Объект конфигурации приложения.
   */
  constructor(
    appConfig: IAppConfig,
  ) {
    ImgFileFromApi.appConfig = appConfig;
  }

  /**
   * Создание объекта статического класса.
   * @param appConfig - Объект конфигурации приложения.
   */
  static getInstance(appConfig: IAppConfig): ImgFileFromApi {
    if (!this.apiImgFileComponent) {
      this.apiImgFileComponent = new ImgFileFromApi(appConfig);
    }
    return this.apiImgFileComponent;
  }

  /**
   * Формирование полной URI в соответствии с версией API из конфига.
   * @param suffix - Часть URN.
   */
  private static createUri(suffix: string): string {
    return ImgFileFromApi.appConfig.api.backend + ImgFileFromApi.appConfig.api.endpoint + suffix;
  }

  /**
   * Возвращается URN для загрузки файла по ID файла.
   * @param id   - Уникальный идентификатор файла.
   * @param mode - Режим загрузки файла.
   */
  private static fileUrnById(id: number, mode: string = 'inline'): string {
    return ImgFileFromApi.createUri(`/file/${id}?mode=${mode}`);
  }

  /**
   * Загрузчик картинок для ngSrc.
   * @param config - Конфигурация загрузки картинки.
   */
  public fromApi(config: ImageLoaderConfig): string {
    let prm: IApiImgFileConfig;
    let uri: string;

    if (config.loaderParams === undefined) {
      uri = config.src;
      return uri;
    }
    prm = config.loaderParams as IApiImgFileConfig;
    if (!prm.api) {
      uri = config.src;
      return uri;
    }
    uri = ImgFileFromApi.fileUrnById(Number(config.src));

    return uri;
  }
}
