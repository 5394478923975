import { Component } from '@angular/core';


@Component({
  selector: 'app-miniApp',
  templateUrl: './miniapp.component.html',
  styleUrl: './miniapp.component.scss'
})
export class MiniAppComponent {
  /** Конструктор. */
  constructor() {
  }
}
