import { NgModule } from '@angular/core';
import { CommonModule, IMAGE_LOADER } from '@angular/common';
import { JsonPipe, NgClass, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";

import { APP_CONFIG } from "../app.config";
import { ImgFileFromApi } from "../_loader";
import { ByteSizePipe, LocalizedDatePipe, PipesModule, RublesPipe, UsdPipe } from "../_pipes";
import { AppInputNumberModule } from "../_components";
import { DropboxAppCityModule, TelegramUnknownUserModule } from "../_ui";
import { CalculatorComponent } from "./calculator";
import { SearchComponent } from "./search";
import { SelectComponent } from "./select";

import { MiniAppRoutingModule } from './miniapp-routing.module';
import { MiniAppComponent } from "./miniapp.component";
import { TextareaContentHeightModule } from "../_directives";


@NgModule({
  declarations: [
    MiniAppComponent,
    CalculatorComponent,
    SearchComponent,
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    NgIf,
    NgForOf,
    JsonPipe,
    ReactiveFormsModule,
    NgClass,
    NgxMaskDirective,
    NgTemplateOutlet,
    AppInputNumberModule,
    FormsModule,
    PipesModule,
    DropboxAppCityModule,
    MiniAppRoutingModule,
    SelectComponent,
    TextareaContentHeightModule,
    TelegramUnknownUserModule,
  ],
  exports: [
    MiniAppComponent,
    SelectComponent,
    CalculatorComponent,
    SearchComponent,
  ],
  providers: [
    PipesModule,
    NgxMaskPipe,
    LocalizedDatePipe,
    RublesPipe,
    UsdPipe,
    ByteSizePipe,
    {
      provide: IMAGE_LOADER,
      useValue: ImgFileFromApi.getInstance(APP_CONFIG).fromApi,
    },
  ],
})
export class MiniAppModule {
}
