import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { lastValueFrom, Subscription } from "rxjs";

import { ByteSizePipe } from "../../_pipes";
import { ConditionTelegram, TButton } from "../../_classes";
import { FileRestApiService, IUploadFileResponse, TelegramRestApiService, TelegramService } from "../../_services";
import { ISearchRequest, SearchService } from "../../_services";


@Component({
  selector: 'miniApp-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent extends ConditionTelegram implements OnInit, OnDestroy {
  private formChangesSubscription?: Subscription = undefined; // Подписка на событие изменения данных формы.
  private isReadyKeys$: boolean; /////////////////////////////// Успешно загружен ключ пользователя.
  private selectedFile?: File; ///////////////////////////////// Выбранный для загрузки файл.

  public form!: FormGroup;

  /** Ссылка на элемент выбора файла. */
  @ViewChild('fileInput', {read: ElementRef}) public fileInput$!: ElementRef<HTMLInputElement>;

  /**
   * Конструктор.
   * @param telegramService    - Сервис работы с бек-энд сервером.
   * @param tg                 - Сервис работы с телеграм.
   * @param activatedRoute     - Сервис изменения состояния роутинга.
   * @param router             - Сервис роутинга ангуляр.
   * @param fileApiService     - Сервис работы с файлами на сервере.
   * @param byteSizePipe       - Форматирование байт.
   * @param search             - Сервис работы с запросами поиска товаров.
   */
  constructor(
    telegramService: TelegramRestApiService,
    tg: TelegramService,
    activatedRoute: ActivatedRoute,
    private router: Router,
    private fileApiService: FileRestApiService,
    private byteSizePipe: ByteSizePipe,
    private search: SearchService,
  ) {
    super(telegramService, tg, activatedRoute);
    [this.isReadyKeys$] = [false];
    [this.selectedFile] = [undefined];
    this.initCondition();
  }

  /** Инициализатор. */
  ngOnInit(): void {
    this.onInit();
    this.initForm();
    this.conditionSubscription = this.conditionEvent.subscribe((_: void): void => this.onCondition());
    this.conditionEvent.next(void {});
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    this.onDestroy();
    if (this.formChangesSubscription !== undefined) {
      this.formChangesSubscription.unsubscribe();
      this.formChangesSubscription = undefined;
    }
  }

  /**
   * Функция вызывается после аутентификации пользователя на бек-энд сервере и полной готовности
   * API библиотеки работы с телеграм клиентом.
   * @protected
   */
  override onReady(): void {
    this.isReadyKeys$ = true;
  }

  /** Настройка шаблона состояний. */
  private initCondition(): void {
    this.condition.pattern = {
      [1]: { // Ввод данных запроса поиска товара.
        ButtonBack: {isShow: true, click: async (_bt: TButton): Promise<void> => await this.stepDec()},
        isCloseConfirm: true,
        isExpand: true,
        ButtonMain: {
          text: 'Отправить запрос', color_text: '#FFFFFF', color_bg: '#5E15E9', isActive: false, isHide: true,
          click: async (bt: TButton): Promise<void> => this.clickSendData(bt),
        },
      },
      [2]: { // Отображение результата отправки данных.
        ButtonBack: {isShow: true, click: async (_bt: TButton): Promise<void> => await this.stepDec()},
        isCloseConfirm: false,
        isExpand: true,
        ButtonMain: {
          text: 'В начало', color_text: '#FFFFFF', color_bg: '#5E15E9', isActive: true,
          click: async (bt: TButton): Promise<void> => this.clickClean(bt),
        },
      },
    };
  }

  /** Обработка события изменения состояния приложения. */
  override onCondition(): void {
    const urn: string = '/miniapp';
    super.onCondition();
    if (this.step !== 0) {
      return
    }
    // Переход на главный экран выбора приложения.
    this.router.navigateByUrl(urn).then((_: boolean): void => {
      // console.log(`Выполнен переход на URN: ${urn}`);
    });
  }

  /** Создание формы. */
  private initForm(): void {
    this.form = new FormGroup({
      text: new FormControl<string>('', [
        Validators.required,
      ]),
      file: new FormControl<number[]>([]),
    });
    // Сброс данных полей формы для того чтобы отображался заполнитель полей, содержащий подсказки.
    this.form.controls['text'].reset();
    if (this.fgs<number[]>(this.form, 'file').value === null) this.fgs<number[]>(this.form, 'file').value = [];
    // Подписка на событие изменения данных формы.
    this.formChangesSubscription = this.form.valueChanges.subscribe((): void => {
      switch (this.step) {
        case 1: // Шаг 3: Ввод данных запроса поиска товара.
          if (this.form.invalid) {
            this.tg.Api.MainButton.disable();
            this.tg.Api.MainButton.hide();
          } else {
            this.tg.Api.MainButton.enable();
            this.tg.Api.MainButton.show();
          }
          break;
        default:
      }
    });
    this.form.updateValueAndValidity();
  }

  // Формирование запроса на расчёт по данным формы.
  private formToSearchRequest(form: FormGroup): ISearchRequest {
    let ret: ISearchRequest;
    ret = {
      text: this.fgs<string>(form, 'text').value,
      file: this.fgs<number[]>(form, 'file').value,
    };
    return ret;
  }

  /** Истина, если поле было "тронуто" и содержит не корректные данные. */
  public isFieldInvalid(field: string): boolean {
    const f: AbstractControl<any, any> | null = this.form.get(field);
    let ret: boolean = false;
    this.form.controls[field].updateValueAndValidity();
    if (f !== null && f.touched) ret = f.invalid && (f.dirty || f.touched);
    return ret;
  }

  /** Добытчик текущего шага состояния. */
  public get step(): number {
    return this.condition.step;
  }

  /** Установщик текущего шага состояния. */
  public set step(n: number) {
    this.condition.step = n;
    this.conditionEvent.next(void {});
  }

  /** Общая готовность компонента. */
  public get isReady(): boolean {
    let ret: boolean;
    ret = this.isReadyKeys$;
    return ret;
  }

  /** Уменьшение значения шага на единицу. */
  public async stepDec(): Promise<void> {
    this.step--;
  }

  /** Увеличение значения шага на единицу. */
  public async stepInc(): Promise<void> {
    this.step++;
  }

  /**
   * Фабрика добытчика и установщика для полей формы.
   * @param fg    - Объект формы, тип FormGroup.
   * @param field - Название поля формы.
   */
  public fgs<T>(fg: FormGroup, field: string) {
    return new class {
      public get value(): T {
        return (fg.controls[field].value as T);
      }

      public set value(v: T) {
        fg.controls[field].setValue(v);
        fg.controls[field].markAsTouched();
      }
    }
  }

  /** Добытчик массива идентификаторов файлов. */
  public get files(): number[] {
    const obj = this.fgs<number[]>(this.form, 'file');
    let ret: number[] = [];
    if (obj && obj.value !== null) obj.value.forEach((v: number): number => ret.push(v));
    return ret;
  }

  /** Установщик массива идентификаторов файлов. */
  public set files(arr: number[]) {
    this.fgs<number[]>(this.form, 'file').value = arr;
  }

  /**
   * Выполнение отправки данных формы на сервер.
   * @param _bt - Объект кнопки мини приложения телеграм.
   */
  public async clickSendData(_bt: TButton): Promise<void> {
    let csp: number;
    let req: ISearchRequest;

    // Дополнительная проверка на всякий случай.
    this.form.controls['text'].updateValueAndValidity();
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      return;
    }
    csp = this.condition.step;
    // Отображение на кнопке индикации ожидания.
    this.condition.pattern[csp].ButtonMain!.click = undefined;
    this.tg.Api.MainButton.showProgress(0);
    // Формирование запроса на расчёт.
    req = this.formToSearchRequest(this.form);
    // Отправка данных на сервер.
    this.search.SearchRequestByData(req)
      .then((): Promise<void> => this.stepInc())
      .catch((e: unknown): void => {
        console.error(`Отправка данных на сервер прервана ошибкой: ${e}`);
        this.tg.PopupAlert(`Отправка данных на сервер прервана ошибкой: ${e}`);
      })
      .finally((): void => {
        this.condition.pattern[csp].ButtonMain!.click = async (bt: TButton): Promise<void> => this.clickSendData(bt);
        this.tg.Api.MainButton.hideProgress();
        this.tg.Api.MainButton.enable();
      });
  }

  /** Сброс результатов и переход на шаг 1. */
  public async clickClean(_bt: TButton): Promise<void> {
    this.form.reset();
    this.condition.step = 1;
    this.conditionEvent.next(void {});
  }

  /* *****************************************************************************************************************
   * Работа с файлами.
   */

  /**
   * Если был выбран файл, выполнение загрузки файла на сервер.
   * @private
   */
  private async onFile(): Promise<void> {
    let rsp: IUploadFileResponse[] = [];
    if (this.selectedFile === undefined) return;

    try {
      rsp = await lastValueFrom(this.fileApiService.uploadFile(this.selectedFile));
    } catch (e: any) {
      console.error(`Сохранение файла на сервере прервано ошибкой: ${e}`);
    }
    if (rsp.length > 0) {
      if (this.fgs<number[]>(this.form, 'file').value === null) this.fgs<number[]>(this.form, 'file').value = [];
      this.fgs<number[]>(this.form, 'file').value.push(rsp[0].id);
      if (this.fileInput$) {
        this.selectedFile = undefined;
        this.fileInput$.nativeElement.value = '';
      }
    }
  }

  /**
   * Событие выбора файла для загрузки на сервер.
   * Проверяется тип файла и размер файла.
   * @param $event - Объект события.
   */
  public async onFileSelected($event: Event): Promise<void> {
    const acceptTypes: string[] = ['image/png', 'image/gif', 'image/jpeg'];
    const sizeMax: number = 1024 * 1024 * 20;
    const target = $event.target as HTMLInputElement;
    let isAccept: boolean = false;
    let file: File;

    if (!target.files || target.files.length <= 0) return;
    file = target.files[0];
    // Проверка типа выбранного файла.
    acceptTypes.forEach((t: string): void => {
      if (file.type.toLowerCase() === t) isAccept = true;
    });
    if (!isAccept) {
      this.tg.PopupAlert(
        `Вы можете загрузить только фотографии\n(png, jpeg, gif)\n\n` +
        `Выбранный вами файл ('${file.type}') мы не принимаем.`,
      ).then((): void => {
        // console.log('Всплывающее сообщение закрыто.');
      })
      return
    }
    // Проверка размера выбранного файла.
    if (file.size > sizeMax) {
      this.tg.PopupAlert(
        `Пожалуйста, загружайте фотографии размером\n` +
        `до ${this.byteSizePipe.transform(sizeMax)}, ` +
        `вы выбрали файл размером ${this.byteSizePipe.transform(file.size)}.`,
      ).then((): void => {
        // console.log('Всплывающее сообщение закрыто.');
      })
      return
    }
    this.selectedFile = file;
    await this.onFile();
  }

  /**
   * Событие клика по кнопке выбора файла.
   * @param _$event - Объект события.
   */
  public async onButtonFile(_$event: Event): Promise<void> {
    if (this.fileInput$ === undefined) return;
    this.fileInput$.nativeElement.click();
  }

  /**
   * Запрос и удаление файла, а так же удаление файла из массива.
   * @param pos     - Позиция файла в массиве.
   */
  public async clickFileDelete(pos: number): Promise<void> {
    this.tg.PopupConfirm("Удалить файл?")
      .then((v: boolean): void => {
        let tmp: number[];
        if (!v) return;
        tmp = this.files;
        tmp.splice(pos, 1);
        this.files = tmp;
      });
  }

  /*
   * /Работа с файлами.
   * *****************************************************************************************************************/
}
