import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { NgOptimizedImage } from "@angular/common";


@Component({
  selector: 'miniApp-select',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss'
})
export class SelectComponent {
  /** Конструктор. */
  constructor(
    private router: Router,
  ) {
  }

  public async go(urn: string): Promise<void> {
    this.router.navigateByUrl(urn).then((_: boolean): void => {
      // console.log(`Выполнен переход по URN: ${urn}`);
    });
  }
}
