<form
  *ngIf="isReady"
  [formGroup]="form">
  <!-- Логотип. -->
  <div
    *ngIf="step === 1 || step === 2"
    class="w-full flex justify-center">
    <img ngSrc="assets/images/logo-vector.svg" height="161" width="199" alt="Логотип">
  </div>
  <!-- /Логотип. -->

  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- Шаг 1: Выбор страны пункта назначения. -->
  <div *ngIf="step === 1">
    <!-- Заголовок и описание. -->
    <div class="w-full pt-[28px]">
      <div class="w-full">
        <h2 class="w-full text-[28px] font-bold text-center">Калькулятор стоимости доставки</h2>
      </div>
      <div
        class="px-[36px] pt-4 text-[20px]">Пожалуйста выберите страну, куда будет доставлен ваш груз или посылка.
      </div>
    </div>
    <!-- /Заголовок и описание. -->

    <!-- Список доступных стран. -->
    <button
      *ngFor="let cy of country"
      (click)="codeSet(cy.code)"
      class="button_country flex flex-row gap-[16px]">
      <img ngSrc="{{cy.flag}}" alt="{{ cy.name }}" width="{{cy.flagWidth}}" height="{{cy.flagHeight}}">
      <div>{{ cy.name }}</div>
    </button>
    <!-- /Список доступных стран. -->
  </div>
  <!-- /Шаг 1: Выбор страны пункта назначения. -->

  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- Шаг 2: Выбор категории груза. -->
  <div *ngIf="step === 2">
    <!-- Заголовок и описание. -->
    <div class="w-full pt-[28px]">
      <div class="w-full">
        <h2 class="w-full text-[28px] font-bold text-center">Калькулятор стоимости доставки</h2>
      </div>
      <div
        class="px-[36px] pt-4 text-[20px]">Пожалуйста, выберите категорию отправления, груз или посылка.
      </div>
    </div>
    <!-- /Заголовок и описание. -->

    <!-- Выбор категории отправления. -->
    <div
      *ngFor="let d7e of departure"
      (click)="onDepartureChange(d7e)"
      class="select_departure flex flex-row gap-[16px]">
      <div *ngIf="d7e === 'package'">Посылка</div>
      <div *ngIf="d7e === 'cargo'">Груз</div>
    </div>
    <!-- /Выбор категории отправления. -->
  </div>
  <!-- /Шаг 2: Выбор категории груза. -->

  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- Шаг 3: Ввод данных об отправлении. -->
  <div *ngIf="step === 3" class="flex flex-col gap-4">
    <!-- Заголовок и описание. -->
    <div class="w-full pt-[28px]">
      <div class="w-full">
        <h2 class="w-full text-[28px] font-bold text-center">Калькулятор стоимости доставки</h2>
      </div>
    </div>
    <!-- /Заголовок и описание. -->

    <!-- Выбор категории отправления. -->
    <div class="flex flex-col gap-[8px]">
      <!-- Выбор категории отправления. -->
      <div
        *ngFor="let d7e of departure"
        (click)="onDepartureChange(d7e)"
        [ngClass]="{'border-2 border-[#5E15E9]': isDeparture(d7e)}"
        class="select_departure_short flex flex-row">
        <div *ngIf="d7e === 'package'">Посылка</div>
        <div *ngIf="d7e === 'cargo'">Груз</div>
        <img
          *ngIf="isDeparture(d7e)"
          ngSrc="assets/images/checkbox-checked.svg" width="22" height="25"
          class="ml-auto" alt="d7e">
        <img
          *ngIf="!isDeparture(d7e)"
          ngSrc="assets/images/checkbox-unchecked.svg" width="22" height="25"
          class="ml-auto" alt="d7e">
      </div>
      <!-- /Выбор категории отправления. -->

      <!-- Город отправки груза. -->
      <app-dropbox-app-city
        *ngIf="isDeparture('cargo')"
        [staticData]="deliveryCostDropboxData"
        [(selectId)]="fgsNum(form, 'fromCity').value"
        placeholder="Выберите город отправления груза"
        [prefix]="'Город отправления груза: '"
        [isInvalid]="isFieldInvalid('fromCity')" [isTouched]="isFieldTouched('fromCity')"
        [help]="['Пожалуйста, выберите город отправки груза из Китая.']"
      ></app-dropbox-app-city>
      <!-- /Город отправки груза. -->
    </div>
    <!-- /Выбор категории отправления. -->

    <!-- Подсказка над полями ввода данных. -->
    <div class="w-full">
      <div class="w-full">
        <h3 class="w-full text-[16px] leading-6">Пожалуйста, укажите вес и размеры</h3>
      </div>
    </div>
    <!-- /Подсказка над полями ввода данных. -->

    <!-- Поля ввода данных об отправлении. -->
    <div class="flex flex-col gap-[8px]">
      <!-- Вес, брутто (кг.). -->
      <app-input-number
        [(value)]="fgsNum(form, 'weightGross').value"
        [isInvalid]="isFieldInvalid('weightGross')" [isTouched]="isFieldTouched('weightGross')"
        [prefix]="'Вес: '" [suffix]="' кг.'" [placeholder]="'Вес, килограммы.'"
        [help]="[
          'Пожалуйста, укажите вес в килограммах.',
          'Посылка не может быть больше 49 кг.',
          'Груз не может быть меньше 50 кг.']"
      ></app-input-number>
      <!-- /Вес, брутто (кг.). -->

      <!-- Ширина. -->
      <app-input-number
        [(value)]="fgsNum(form, 'width').value"
        [isInvalid]="isFieldInvalid('width')" [isTouched]="isFieldTouched('width')"
        [prefix]="'Ширина: '" [suffix]="' м.'" [placeholder]="'Ширина, метры.'"
        [help]="['Пожалуйста, укажите ширину в метрах.']"
      ></app-input-number>
      <!-- /Ширина. -->

      <!-- Высота. -->
      <app-input-number
        [(value)]="fgsNum(form, 'height').value"
        [isInvalid]="isFieldInvalid('height')" [isTouched]="isFieldTouched('height')"
        [prefix]="'Высота: '" [suffix]="' м.'" [placeholder]="'Высота, метры.'"
        [help]="['Пожалуйста, укажите высоту в метрах.']"
      ></app-input-number>
      <!-- /Высота. -->

      <!-- Длинна. -->
      <app-input-number
        [(value)]="fgsNum(form, 'length').value"
        [isInvalid]="isFieldInvalid('length')" [isTouched]="isFieldTouched('length')"
        [prefix]="'Длинна: '" [suffix]="' м.'" [placeholder]="'Длинна, метры.'"
        [help]="['Пожалуйста, укажите длину в метрах.']"
      ></app-input-number>
      <!-- /Длинна. -->
    </div>
    <!-- /Поля ввода данных об отправлении. -->

    <!-- Ошибка плотности отправления. -->
    <div
      *ngIf="densityWarning"
      class="w-full">
      <div class="w-full text-[14px] text-[#FF0000]">
        <p class="w-full">Для категории отправления "груз", плотность должна быть не меньше {{ minThresholdForCargo }}
          <span class="frac">
            <sup>кг.</sup>
            <span>&frasl;</span>
            <sub>м.<sup>3</sup></sub>
          </span>
        </p>
        <p>Плотность груза по введённым вами значениям составляет: {{ density }}&nbsp;
          <span class="frac">
            <sup>кг.</sup>
            <span>&frasl;</span>
            <sub>м.<sup>3</sup></sub>
          </span>
        </p>
        <br><br><br>
      </div>
    </div>
    <!-- /Ошибка плотности отправления. -->
  </div>
  <!-- /Шаг 3: Ввод данных об отправлении. -->

  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- Шаг 4: Отображение результата расчётов. -->
  <div *ngIf="step === 4" class="flex flex-col gap-4">
    <!-- Заголовок и описание. -->
    <div class="w-full pt-[28px]">
      <div class="w-full">
        <h2 class="w-full text-[28px] font-bold text-center">Калькулятор стоимости доставки</h2>
      </div>
    </div>
    <!-- /Заголовок и описание. -->

    <!-- Выбор категории отправления. -->
    <div class="flex flex-col gap-[8px]">
      <div
        *ngFor="let d7e of departure"
        [ngClass]="{
          'border-2 border-[#5E15E9] flex flex-row': isDeparture(d7e),
          'hidden': !isDeparture(d7e)}"
        class="select_departure_short">
        <div *ngIf="d7e === 'package' && isDeparture(d7e)">Посылка</div>
        <div *ngIf="d7e === 'cargo' && isDeparture(d7e)">Груз</div>
        <img
          *ngIf="isDeparture(d7e)"
          ngSrc="assets/images/checkbox-checked.svg" width="22" height="25"
          class="ml-auto" alt="d7e">
        <img
          *ngIf="!isDeparture(d7e)"
          ngSrc="assets/images/checkbox-unchecked.svg" width="22" height="25"
          class="ml-auto" alt="d7e">
      </div>
    </div>
    <!-- /Выбор категории отправления. -->

    <!-- Город отправки груза. -->
    <app-dropbox-app-city
      *ngIf="isDeparture('cargo')"
      [staticData]="deliveryCostDropboxData"
      [(selectId)]="fgsNum(form, 'fromCity').value"
      placeholder="Выберите город отправления груза"
      [prefix]="'Город отправления груза: '"
      [isInvalid]="isFieldInvalid('fromCity')" [isTouched]="isFieldTouched('fromCity')" [isReadonly]="true"
      [help]="['Пожалуйста, выберите город отправки груза из Китая.']"
    ></app-dropbox-app-city>
    <!-- /Город отправки груза. -->

    <!-- Подсказка над полями ввода данных. -->
    <div class="w-full">
      <div class="w-full">
        <h3 class="w-full text-[16px] leading-6">Пожалуйста, укажите вес и размеры</h3>
      </div>
    </div>
    <!-- /Подсказка над полями ввода данных. -->

    <!-- Поля с введёнными значениями в режиме "только для чтения" -->
    <div class="flex flex-col gap-[8px]">
      <!-- Вес, брутто (кг.). -->
      <app-input-number
        [(value)]="fgsNum(form, 'weightGross').value"
        [isInvalid]="isFieldInvalid('weightGross')" [isTouched]="isFieldTouched('weightGross')" [isReadonly]="true"
        [prefix]="'Вес: '" [suffix]="' кг.'" [placeholder]="'Вес, килограммы.'"
        [help]="[
          'Пожалуйста, укажите вес в килограммах.',
          'Посылка не может быть больше 49 кг.',
          'Груз не может быть меньше 50 кг.']"
      ></app-input-number>
      <!-- /Вес, брутто (кг.). -->

      <!-- Ширина. -->
      <app-input-number
        [(value)]="fgsNum(form, 'width').value"
        [isInvalid]="isFieldInvalid('width')" [isTouched]="isFieldTouched('width')" [isReadonly]="true"
        [prefix]="'Ширина: '" [suffix]="' м.'" [placeholder]="'Ширина, метры.'"
        [help]="['Пожалуйста, укажите ширину в метрах.']"
      ></app-input-number>
      <!-- /Ширина. -->

      <!-- Высота. -->
      <app-input-number
        [(value)]="fgsNum(form, 'height').value"
        [isInvalid]="isFieldInvalid('height')" [isTouched]="isFieldTouched('height')" [isReadonly]="true"
        [prefix]="'Высота: '" [suffix]="' м.'" [placeholder]="'Высота, метры.'"
        [help]="['Пожалуйста, укажите высоту в метрах.']"
      ></app-input-number>
      <!-- /Высота. -->

      <!-- Длинна. -->
      <app-input-number
        [(value)]="fgsNum(form, 'length').value"
        [isInvalid]="isFieldInvalid('length')" [isTouched]="isFieldTouched('length')" [isReadonly]="true"
        [prefix]="'Длинна: '" [suffix]="' м.'" [placeholder]="'Длинна, метры.'"
        [help]="['Пожалуйста, укажите длину в метрах.']"
      ></app-input-number>
      <!-- /Длинна. -->
    </div>
    <!-- /Поля с введёнными значениями в режиме "только для чтения" -->

    <!-- Подсказка над полями вывода результата. -->
    <div class="w-full">
      <div class="w-full">
        <h3 class="w-full text-[16px] leading-6">Результат расчётов:</h3>
      </div>
    </div>
    <!-- /Подсказка над полями вывода результата. -->

    <!-- Результаты расчётов. -->
    <div class="flex flex-col gap-2">
      <!-- Пункты стоимости доставки. -->
      <div
        *ngFor="let item of calcResult.items"
        class="output_result">
        <div>{{ item.name !== '' ? item.name + ':' : '-' }}</div>
        <div class="output_cost">{{ item.cost | usd }}</div>
      </div>
      <!-- /Пункты стоимости доставки. -->

      <!-- Итого. -->
      <div class="output_result">
        <div>Итого:</div>
        <div class="output_cost">{{ calcResult.cost | usd }}</div>
      </div>
      <!-- /Итого. -->
    </div>
    <!-- /Результаты расчётов. -->
  </div>
  <!-- /Шаг 4: Отображение результата расчётов. -->

  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- Шаг 10: Россия. Ввод данных об отправлении. -->
  <div *ngIf="step === 10" class="flex flex-col gap-4">
    <!-- Подсказка над полями ввода данных. -->
    <div class="w-full pt-[28px]">
      <div class="w-full">
        <h3 class="w-full text-[16px] leading-6">Пожалуйста, укажите вес, размеры и прикрепите изображение товара.</h3>
      </div>
    </div>
    <!-- /Подсказка над полями ввода данных. -->

    <!-- Поля ввода данных об отправлении. -->
    <div class="flex flex-col gap-[8px]">
      <!-- Вес, брутто (кг.). -->
      <app-input-number
        [(value)]="fgsNum(form, 'weightGross').value"
        [isInvalid]="isFieldInvalid('weightGross')" [isTouched]="isFieldTouched('weightGross')"
        [prefix]="'Вес: '" [suffix]="' кг.'" [placeholder]="'Вес, килограммы.'"
        [help]="['Пожалуйста, укажите вес в килограммах.']"
      ></app-input-number>
      <!-- /Вес, брутто (кг.). -->

      <!-- Ширина. -->
      <app-input-number
        [(value)]="fgsNum(form, 'width').value"
        [isInvalid]="isFieldInvalid('width')" [isTouched]="isFieldTouched('width')"
        [prefix]="'Ширина: '" [suffix]="' м.'" [placeholder]="'Ширина, метры.'"
        [help]="['Пожалуйста, укажите ширину в метрах.']"
      ></app-input-number>
      <!-- /Ширина. -->

      <!-- Высота. -->
      <app-input-number
        [(value)]="fgsNum(form, 'height').value"
        [isInvalid]="isFieldInvalid('height')" [isTouched]="isFieldTouched('height')"
        [prefix]="'Высота: '" [suffix]="' м.'" [placeholder]="'Высота, метры.'"
        [help]="['Пожалуйста, укажите высоту в метрах.']"
      ></app-input-number>
      <!-- /Высота. -->

      <!-- Длинна. -->
      <app-input-number
        [(value)]="fgsNum(form, 'length').value"
        [isInvalid]="isFieldInvalid('length')" [isTouched]="isFieldTouched('length')"
        [prefix]="'Длинна: '" [suffix]="' м.'" [placeholder]="'Длинна, метры.'"
        [help]="['Пожалуйста, укажите длину в метрах.']"
      ></app-input-number>
      <!-- /Длинна. -->
    </div>
    <!-- /Поля ввода данных об отправлении. -->

    <!-- Блок отображения прикреплённых файлов. -->
    <div
      class="w-full relative flex flex-row flex-wrap">
      <div
        *ngFor="let fileId of files; index as n"
        class="w-[63px] h-[63px] object-contain relative">
        <img
          (click)="clickFileDelete(n)"
          [loaderParams]="{api: true}"
          ngSrc="{{ fileId }}"
          fill
          disableOptimizedSrcset
          alt="Загруженное изображение"
          class="m-1 p-1 rounded-[12px]">
      </div>
    </div>
    <!-- /Блок отображения прикреплённых файлов. -->

    <!-- Кнопка выбора и прикрепления файла. -->
    <input #fileInput type="file" (change)="onFileSelected($event)" class="hidden">
    <button
      (click)="onButtonFile($event)"
      class="button_select_file flex flex-row gap-[16px]">
      <div class="w-full text-center">Добавить фото</div>
    </button>
    <!-- /Кнопка выбора и прикрепления файла. -->
  </div>
  <!-- /Шаг 10: Россия. Ввод данных об отправлении. -->

  <!-- ------------------------------------------------------------------------------------------------------------- -->
  <!-- Шаг 11: Россия. Отображение сообщения о том что запрос был отправлен. -->
  <div *ngIf="step === 11" class="flex flex-col gap-4">
    <!-- Сообщение об успешной отправки данных на сервер. -->
    <div class="w-full pt-[28px] text-[18px]">
      <p class="w-full">
        Мы получили ваш запрос, оператор свяжется с вами в ближайшее время и предоставит стоимость доставки вашего
        груза.
      </p>
      <p>Спасибо.</p>
    </div>
    <!-- /Сообщение об успешной отправки данных на сервер. -->
  </div>
  <!-- /Шаг 11: Россия. Отображение сообщения о том что запрос был отправлен. -->
</form>

<!-- Страничка с ошибкой аутентификации пользователя телеграм. -->
<app-telegram-unknown-user *ngIf="!isReady && isUnassigned"></app-telegram-unknown-user>
<!-- /Страничка с ошибкой аутентификации пользователя телеграм. -->
