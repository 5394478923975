import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CalculatorComponent } from "./calculator";
import { SearchComponent } from "./search";
import { MiniAppComponent } from "./miniapp.component";
import { SelectComponent } from "./select";


const routes: Routes = [
  {
    path: '',
    component: MiniAppComponent,
    children: [
      {
        path: 'select',
        component: SelectComponent,
      },
      {
        path: 'calculator',
        component: CalculatorComponent,
      },
      {
        path: 'search',
        component: SearchComponent,
      },
      {path: '**', redirectTo: 'select'},
    ],
  },

  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MiniAppRoutingModule {
}
