import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Validators } from "@angular/forms";
import { lastValueFrom, Subscription } from "rxjs";

import { ConditionTelegram, IDropboxItem, TButton } from "../../_classes";
import { FileRestApiService, IUploadFileResponse, TelegramRestApiService } from "../../_services";
import { ICalculatorRequest, ICalculatorResult, ICityOfDepartureDeliveryCost } from "../../_services";
import { CalculatorService } from "../../_services";
import { TCountryCode, TDeparture, TelegramService } from "../../_services";
import { DensityForCargo, ICountry, IValidatorWeightByDeparture } from "./calculator.types";
import { ByteSizePipe } from "../../_pipes";


/** Минимальная плотность груза в килограмм на метр кубический. */
const minThresholdForCargo: number = 130;

@Component({
  selector: 'miniApp-calculator',
  templateUrl: './calculator.component.html',
  styleUrl: './calculator.component.scss'
})
export class CalculatorComponent extends ConditionTelegram implements OnInit, OnDestroy {
  private formChangesSubscription?: Subscription = undefined; // Подписка на событие изменения данных формы.
  private isReadyKeys$: boolean; /////////////////////////////// Успешно загружен ключ пользователя.
  private isReadyData$: boolean; /////////////////////////////// Данные для работы формы готовы.
  private selectedFile?: File; ///////////////////////////////// Выбранный для загрузки файл.

  public deliveryCost: ICityOfDepartureDeliveryCost[];
  public deliveryCostDropboxData: IDropboxItem[];
  public country: ICountry[];
  public departure: TDeparture[];
  public form!: FormGroup;
  public density: number;
  public densityWarning: boolean;
  public calcResult: ICalculatorResult;

  /** Ссылка на элемент выбора файла. */
  @ViewChild('fileInput', {read: ElementRef}) public fileInput$!: ElementRef<HTMLInputElement>;

  /**
   * Конструктор.
   * @param telegramService    - Сервис работы с бек-энд сервером.
   * @param tg                 - Сервис работы с телеграм.
   * @param activatedRoute     - Сервис изменения состояния роутинга.
   * @param router             - Сервис роутинга ангуляр.
   * @param calc               - Сервис расчёта стоимости доставки.
   * @param fileApiService     - Сервис работы с файлами на сервере.
   * @param byteSizePipe       - Форматирование байт.
   */
  constructor(
    telegramService: TelegramRestApiService,
    tg: TelegramService,
    activatedRoute: ActivatedRoute,
    private router: Router,
    private calc: CalculatorService,
    private fileApiService: FileRestApiService,
    private byteSizePipe: ByteSizePipe,
  ) {
    super(telegramService, tg, activatedRoute);
    [this.deliveryCost, this.deliveryCostDropboxData, this.country, this.departure] = [[], [], [], []];
    [this.density, this.densityWarning] = [0, false];
    [this.isReadyKeys$, this.isReadyData$] = [false, false];
    [this.selectedFile] = [undefined];
    this.initCondition();
    this.calcResult = {items: [], cost: 0};
  }

  /** Инициализатор. */
  ngOnInit(): void {
    this.onInit();
    this.initData();
    this.initForm();
    this.conditionSubscription = this.conditionEvent.subscribe((_: void): void => this.onCondition());
    this.conditionEvent.next(void {});
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    this.onDestroy();
    if (this.formChangesSubscription !== undefined) {
      this.formChangesSubscription.unsubscribe();
      this.formChangesSubscription = undefined;
    }
  }

  /**
   * Функция вызывается после аутентификации пользователя на бек-энд сервере и полной готовности
   * API библиотеки работы с телеграм клиентом.
   * @protected
   */
  override onReady(): void {
    this.isReadyKeys$ = true;
  }

  /** Настройка шаблона состояний. */
  private initCondition(): void {
    this.condition.pattern = {
      [1]: { // Выбор страны пункта назначения.
        ButtonBack: {isShow: true, click: async (_bt: TButton): Promise<void> => await this.stepDec()},
        isCloseConfirm: false,
        isExpand: true,
      },
      [2]: { // Выбор категории отправления.
        ButtonBack: {isShow: true, click: async (_bt: TButton): Promise<void> => await this.stepDec()},
        isCloseConfirm: true,
        onStep: async (_step: number): Promise<void> => this.form.controls['departure'].setValue(''),
      },
      [3]: { // Ввод данных об отправлении.
        ButtonBack: {isShow: true, click: async (_bt: TButton): Promise<void> => await this.stepDec()},
        isCloseConfirm: true,
        isExpand: true,
        ButtonMain: {
          text: 'Рассчитать', color_text: '#FFFFFF', color_bg: '#5E15E9', isActive: false, isHide: true,
          click: async (bt: TButton): Promise<void> => this.clickCalculate(bt),
        },
      },
      [4]: { // Отображение результатов расчёта.
        ButtonBack: {isShow: true, click: async (_bt: TButton): Promise<void> => await this.stepDec()},
        isCloseConfirm: false,
        isExpand: true,
        ButtonMain: {
          text: 'Сбросить', color_text: '#FFFFFF', color_bg: '#5E15E9', isActive: true,
          click: async (bt: TButton): Promise<void> => this.clickClean(bt),
        }
      },
      [9]: { // Пустой шаг - выполнение редиректа на шаг 1.
        onStep: async (_step: number): Promise<void> => {
          this.step = 1
        },
      },
      [10]: { // Шаг 10 - Россия, Киргизия, Казахстан. Ввод данных об отправлении.
        ButtonBack: {isShow: true, click: async (_bt: TButton): Promise<void> => await this.stepDec()},
        isCloseConfirm: true,
        isExpand: true,
        ButtonMain: {
          text: 'Отправить запрос', color_text: '#FFFFFF', color_bg: '#5E15E9', isActive: false, isHide: false,
          click: async (bt: TButton): Promise<void> => this.clickSendData(bt),
        },
      },
      [11]: { // Шаг 11 - Россия, Киргизия, Казахстан. Отображение результата отправки данных.
        ButtonBack: {isShow: true, click: async (bt: TButton): Promise<void> => this.clickClean(bt)},
        isCloseConfirm: false,
        isExpand: true,
        ButtonMain: {
          text: 'В начало', color_text: '#FFFFFF', color_bg: '#5E15E9', isActive: true,
          click: async (bt: TButton): Promise<void> => this.clickClean(bt),
        }
      }
    };
  }

  /** Загрузка или подготовка данных справочников. */
  private initData(): void {
    // Загрузка стоимости доставки в таджикистан в зависимости от города отправки в китае.
    this.calc.CityDeliveryCost()
      .then((value: ICityOfDepartureDeliveryCost[]): void => {
        this.deliveryCost = value;
        value.forEach((v: ICityOfDepartureDeliveryCost): void => {
          this.deliveryCostDropboxData.push({id: v.id, name: v.name});
        });
      })
      .catch((e: unknown): void => {
        console.error(`Загрузка списка городов отправки грузов из Китая прервана ошибкой: ${e}`);
      })
      .finally((): void => {
        this.isReadyData$ = true;
      });
    // Справочник категорий отправления.
    this.departure = ['package', 'cargo'];
    // Справочник стран доставки товаров.
    this.country = [
      {code: 'tjk', name: 'Таджикистан', flag: 'assets/images/flag-tadjikistan.svg', flagWidth: 40, flagHeight: 20},
      {code: 'rus', name: 'Россия', flag: 'assets/images/flag-russia.svg', flagWidth: 40, flagHeight: 20},
      {code: 'kgz', name: 'Киргизия', flag: 'assets/images/flag-kgz.svg', flagWidth: 40, flagHeight: 23},
      {code: 'kaz', name: 'Казахстан', flag: 'assets/images/flag-kaz.svg', flagWidth: 40, flagHeight: 20},
    ];
  }

  /** Обработка события изменения состояния приложения. */
  override onCondition(): void {
    const urn: string = '/miniapp';
    super.onCondition();
    if (this.step !== 0) {
      return
    }
    // Переход на главный экран выбора приложения.
    this.router.navigateByUrl(urn).then((_: boolean): void => {
      // console.log(`Выполнен переход на URN: ${urn}`);
    });
  }

  /**
   * Возвращает функцию проверки значения поля формы.
   * Значение проверяется на условие:
   * 1. Для категории отправления "посылка", вес должен быть до 50 килограмм.
   * 2. Для категории отправления "груз" - вес должен быть от 50 килограмм и выше.
   * @param cfg - Конфигурация проверки значений поля формы.
   */
  private validatorWeightByDeparture(cfg: IValidatorWeightByDeparture): ValidatorFn {
    const errMin: ValidationErrors = {MinQuantity: true};
    const errMax: ValidationErrors = {MaxQuantity: true};
    return (control: AbstractControl): ValidationErrors | null => {
      const val: ValidationErrors | null = control.value;
      let ret: ValidationErrors | null = null;
      let num: number;
      let chk: number | undefined;
      let dep: TDeparture;

      if (val === null || this.form === undefined || this.step >= 10) return ret;
      num = parseInt(val.toString()) | 0;
      dep = this.fgs<TDeparture>(this.form, 'departure').value;
      if (dep !== '') {
        chk = cfg[dep.toString()].min;
        if (chk !== undefined && num < chk) {
          ret = errMin;
        }
        chk = cfg[dep.toString()].max;
        if (ret == null && chk !== undefined && num > chk) {
          ret = errMax;
        }
      }

      return ret;
    }
  }

  /**
   * Требовать указание города только для категории отправления "груз".
   * @private
   */
  private validatorFromCityByDeparture(): ValidatorFn {
    const errCity: ValidationErrors = {Required: true};
    return (control: AbstractControl): ValidationErrors | null => {
      const val: ValidationErrors | null = control.value;
      let ret: ValidationErrors | null = null;
      let num: number;
      let dep: TDeparture;

      if (val === null || this.form === undefined || this.step >= 10) return ret;
      num = parseInt(val.toString()) | 0;
      dep = this.fgs<TDeparture>(this.form, 'departure').value;
      if (dep === 'cargo' && num <= 0) ret = errCity;

      return ret;
    }
  }

  /**
   * Проверка плотности отправления для категории "груз".
   * @private
   */
  private validatorDensityForCargo(cfg: DensityForCargo): ValidatorFn {
    const errLowDensity: ValidationErrors = {densityLow: true};
    return (control: AbstractControl): ValidationErrors | null => {
      const val: ValidationErrors | null = control.value;
      let ret: ValidationErrors | null = null;
      let weight: number;
      let width: number;
      let height: number;
      let length: number;
      let dep: TDeparture;
      let density: number;

      if (val === null || this.form === undefined || this.step >= 10) return ret;
      dep = this.fgs<TDeparture>(this.form, 'departure').value;
      if (dep !== 'cargo') return ret;
      weight = this.fgs<number>(this.form, 'weightGross').value;
      width = this.fgs<number>(this.form, 'width').value;
      height = this.fgs<number>(this.form, 'height').value;
      length = this.fgs<number>(this.form, 'length').value;
      if (weight === null || width === null || height === null || length === null) return ret;
      if (weight <= 0 || width <= 0 || height <= 0 || length <= 0) return ret;
      // Расчёт плотности.
      density = this.calc.density(weight, width, height, length);
      if (density < cfg.thresholdMin) {
        this.density = density;
        ret = errLowDensity;
      }

      return ret;
    }
  }

  /** Создание формы. */
  private initForm(): void {
    this.form = new FormGroup({
      fromCity: new FormControl<number>(0, [
        this.validatorFromCityByDeparture(),
      ]),
      code: new FormControl<TCountryCode>(''),
      departure: new FormControl<TDeparture>('', []),
      weightGross: new FormControl<number>(0, [
        Validators.required,
        this.validatorWeightByDeparture({['package']: {max: 49999}, ['cargo']: {min: 50000}}),
      ]),
      width: new FormControl<number>(0, [
        Validators.required,
        Validators.min(1),
      ]),
      height:
        new FormControl<number>(0, [
          Validators.required,
          Validators.min(1),
        ]),
      length:
        new FormControl<number>(0, [
          Validators.required,
          Validators.min(1),
        ]),
      file: new FormControl<number[]>([]),
    });
    // Общий верификатор плотности груза.
    this.form.addValidators(
      this.validatorDensityForCargo({thresholdMin: minThresholdForCargo}),
    );

    // Сброс данных полей формы для того чтобы отображался заполнитель полей, содержащий подсказки.
    this.form.controls['weightGross'].reset();
    this.form.controls['width'].reset();
    this.form.controls['height'].reset();
    this.form.controls['length'].reset();
    if (this.fgs<number[]>(this.form, 'file').value === null) this.fgs<number[]>(this.form, 'file').value = [];
    // Подписка на событие изменения данных формы.
    this.formChangesSubscription = this.form.valueChanges.subscribe((): void => {
      switch (this.step) {
        case 3: // Шаг 3: Ввод данных об отправлении.
          this.densityWarning = false;
          if (this.form.invalid && this.form.errors !== null) {
            Object.keys(this.form.errors).forEach((key: string): void => {
              if (key === 'densityLow') this.densityWarning = true;
            });
          }
          if (this.form.invalid) {
            this.tg.Api.MainButton.disable();
            this.tg.Api.MainButton.hide();
          } else {
            this.tg.Api.MainButton.enable();
            this.tg.Api.MainButton.show();
          }
          break;
        case 10: // Шаг 10: Россия. Ввод данных об отправлении.
          if (this.form.invalid) {
            this.tg.Api.MainButton.disable();
            this.tg.Api.MainButton.hide();
          } else {
            this.tg.Api.MainButton.enable();
            this.tg.Api.MainButton.show();
          }
          break;
        default:
      }

    });
    this.form.updateValueAndValidity();
  }

  // Формирование запроса на расчёт по данным формы.
  private formToCalculatorRequest(form: FormGroup): ICalculatorRequest {
    let ret: ICalculatorRequest;

    let n: number = 1.000001;
    let a = Math.round(n)

    ret = {
      fromCity: this.fgs<number>(form, 'fromCity').value,
      code: this.fgs<TCountryCode>(form, 'code').value,
      departure: this.fgs<TDeparture>(form, 'departure').value,
      weight: Math.round(this.fgs<number>(form, 'weightGross').value),
      width: Math.round(this.fgs<number>(form, 'width').value),
      height: Math.round(this.fgs<number>(form, 'height').value),
      length: Math.round(this.fgs<number>(form, 'length').value),
      file: this.fgs<number[]>(form, 'file').value,
    };

    return ret;
  }

  /** Истина, если поле было "тронуто" и содержит не корректные данные. */
  public isFieldInvalid(field: string): boolean {
    const f: AbstractControl<any, any> | null = this.form.get(field);
    let ret: boolean = false;
    this.form.controls[field].updateValueAndValidity();
    if (f !== null && f.touched) ret = f.invalid && (f.dirty || f.touched);
    return ret;
  }

  /** Истина, если поле было "тронуто". */
  public isFieldTouched(field: string): boolean {
    const f: AbstractControl<any, any> | null = this.form.get(field);
    let ret: boolean = false;
    if (f !== null && f.touched) ret = (f.dirty || f.touched);
    return ret;
  }

  /** Добытчик текущего шага состояния. */
  public get step(): number {
    return this.condition.step;
  }

  /** Установщик текущего шага состояния. */
  public set step(n: number) {
    this.condition.step = n;
    this.conditionEvent.next(void {});
  }

  /** Общая готовность компонента. */
  public get isReady(): boolean {
    let ret: boolean;
    ret = this.isReadyData$ && this.isReadyKeys$;
    return ret;
  }

  /** Уменьшение значения шага на единицу. */
  public async stepDec(): Promise<void> {
    this.step--;
  }

  /** Увеличение значения шага на единицу. */
  public async stepInc(): Promise<void> {
    this.step++;
  }

  /**
   * Фабрика добытчика и установщика для полей формы.
   * @param fg    - Объект формы, тип FormGroup.
   * @param field - Название поля формы.
   */
  public fgs<T>(fg: FormGroup, field: string) {
    return new class {
      public get value(): T {
        return (fg.controls[field].value as T);
      }

      public set value(v: T) {
        fg.controls[field].setValue(v);
        fg.controls[field].markAsTouched();
      }
    }
  }

  /** Решение проблемы вызова дженерика из шаблона. */
  public fgsNum(fg: FormGroup, field: string) {
    return this.fgs<number>(fg, field)
  }

  /** Добытчик массива идентификаторов файлов. */
  public get files(): number[] {
    const obj = this.fgs<number[]>(this.form, 'file');
    let ret: number[] = [];
    if (obj && obj.value !== null) obj.value.forEach((v: number): number => ret.push(v));
    return ret;
  }

  /** Установщик массива идентификаторов файлов. */
  public set files(arr: number[]) {
    this.fgs<number[]>(this.form, 'file').value = arr;
  }

  /** Значение константы минимальной плотности отправления. */
  public get minThresholdForCargo(): number {
    return minThresholdForCargo;
  }

  /**
   * Установка значения выбора страны доставки товаров.
   * @param code - Код страны.
   */
  public async codeSet(code: TCountryCode): Promise<void> {
    const fcc = this.fgs<TCountryCode>(this.form, 'code')
    fcc.value = code;
    switch (fcc.value) {
      case 'tjk':
        await this.stepInc();
        break;
      default:
        this.step = 10;
        break;
    }
  }

  /**
   * Установка значения выбора категории отправления.
   * @param departure - Категория отправления.
   */
  public async onDepartureChange(departure: TDeparture): Promise<void> {
    this.form.controls['departure'].setValue(departure);
    this.form.controls['fromCity'].updateValueAndValidity();
    this.form.controls['weightGross'].updateValueAndValidity();
    if (this.step === 2) await this.stepInc();
  }

  /**
   * Проверка значения выбранной категории отправления.
   * @param departure - Категория отправления.
   * @returns - Истина, если выбрана указанная категория отправления.
   */
  public isDeparture(departure: TDeparture): boolean {
    return this.form.controls['departure'].value === departure;
  }

  /**
   * Выполнение расчёта стоимости доставки.
   * @param _bt - Объект кнопки мини приложения телеграм.
   */
  public async clickCalculate(_bt: TButton): Promise<void> {
    let req: ICalculatorRequest;

    // Дополнительная проверка на всякий случай.
    this.form.controls['fromCity'].updateValueAndValidity();
    this.form.controls['weightGross'].updateValueAndValidity();
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      return;
    }
    // Отображение на кнопке индикации ожидания.
    this.tg.Api.MainButton.showProgress(0);
    // Формирование запроса на расчёт.
    req = this.formToCalculatorRequest(this.form);
    // Расчёт стоимости через сервис.
    this.calc.Calculate(req)
      .then((result: ICalculatorResult): void => {
        this.calcResult = result;
        this.stepInc();
      })
      .catch((e: unknown): void => {
        this.tg.PopupAlert(`${e}`);
      })
      .finally((): void => {
        this.tg.Api.MainButton.hideProgress();
      });
  }

  /** Сброс результатов и переход на шаг 1. */
  public async clickClean(_bt: TButton): Promise<void> {
    this.form.reset();
    this.condition.step = 1;
    this.conditionEvent.next(void {});
  }

  /**
   * Выполнение отправки данных формы на сервер.
   * @param _bt - Объект кнопки мини приложения телеграм.
   */
  public async clickSendData(_bt: TButton): Promise<void> {
    let csp: number;
    let req: ICalculatorRequest;

    // Дополнительная проверка на всякий случай.
    this.form.controls['weightGross'].updateValueAndValidity();
    this.form.controls['width'].updateValueAndValidity();
    this.form.controls['height'].updateValueAndValidity();
    this.form.controls['length'].updateValueAndValidity();
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      return;
    }
    csp = this.condition.step;
    // Отображение на кнопке индикации ожидания.
    this.condition.pattern[csp].ButtonMain!.click = undefined;
    this.tg.Api.MainButton.showProgress(0);
    // Формирование запроса на расчёт.
    req = this.formToCalculatorRequest(this.form);
    // Отправка данных на сервер.
    this.calc.CalculateRequestByData(req)
      .then((): Promise<void> => this.stepInc())
      .catch((e: unknown): void => {
        console.error(`Отправка данных на сервер прервана ошибкой: ${e}`);
        this.tg.PopupAlert(`Отправка данных на сервер прервана ошибкой: ${e}`);
      })
      .finally((): void => {
        this.condition.pattern[csp].ButtonMain!.click = async (bt: TButton): Promise<void> => this.clickSendData(bt);
        this.tg.Api.MainButton.hideProgress();
        this.tg.Api.MainButton.enable();
      });
  }

  /* *****************************************************************************************************************
   * Работа с файлами.
   */

  /**
   * Если был выбран файл, выполнение загрузки файла на сервер.
   * @private
   */
  private async onFile(): Promise<void> {
    let rsp: IUploadFileResponse[] = [];
    if (this.selectedFile === undefined) return;

    try {
      rsp = await lastValueFrom(this.fileApiService.uploadFile(this.selectedFile));
    } catch (e: any) {
      console.error(`Сохранение файла на сервере прервано ошибкой: ${e}`);
    }
    if (rsp.length > 0) {
      if (this.fgs<number[]>(this.form, 'file').value === null) this.fgs<number[]>(this.form, 'file').value = [];
      this.fgs<number[]>(this.form, 'file').value.push(rsp[0].id);
      if (this.fileInput$) {
        this.selectedFile = undefined;
        this.fileInput$.nativeElement.value = '';
      }
    }
  }

  /**
   * Событие выбора файла для загрузки на сервер.
   * Проверяется тип файла и размер файла.
   * @param $event - Объект события.
   */
  public async onFileSelected($event: Event): Promise<void> {
    const acceptTypes: string[] = ['image/png', 'image/gif', 'image/jpeg'];
    const sizeMax: number = 1024 * 1024 * 20;
    const target = $event.target as HTMLInputElement;
    let isAccept: boolean = false;
    let file: File;

    if (!target.files || target.files.length <= 0) return;
    file = target.files[0];
    // Проверка типа выбранного файла.
    acceptTypes.forEach((t: string): void => {
      if (file.type.toLowerCase() === t) isAccept = true;
    });
    if (!isAccept) {
      this.tg.PopupAlert(
        `Вы можете загрузить только фотографии\n(png, jpeg, gif)\n\n` +
        `Выбранный вами файл ('${file.type}') мы не принимаем.`,
      ).then((): void => {
        // console.log('Всплывающее сообщение закрыто.');
      })
      return
    }
    // Проверка размера выбранного файла.
    if (file.size > sizeMax) {
      this.tg.PopupAlert(
        `Пожалуйста, загружайте фотографии размером\n` +
        `до ${this.byteSizePipe.transform(sizeMax)}, ` +
        `вы выбрали файл размером ${this.byteSizePipe.transform(file.size)}.`,
      ).then((): void => {
        // console.log('Всплывающее сообщение закрыто.');
      })
      return
    }
    this.selectedFile = file;
    await this.onFile();
  }

  /**
   * Событие клика по кнопке выбора файла.
   * @param _$event - Объект события.
   */
  public async onButtonFile(_$event: Event): Promise<void> {
    if (this.fileInput$ === undefined) return;
    this.fileInput$.nativeElement.click();
  }

  /**
   * Запрос и удаление файла, а так же удаление файла из массива.
   * @param pos     - Позиция файла в массиве.
   */
  public async clickFileDelete(pos: number): Promise<void> {
    this.tg.PopupConfirm("Удалить файл?")
      .then((v: boolean): void => {
        let tmp: number[];
        if (!v) return;
        tmp = this.files;
        tmp.splice(pos, 1);
        this.files = tmp;
      });
  }

  /*
   * /Работа с файлами.
   * *****************************************************************************************************************/
}
