<!-- Логотип. -->
<div class="w-full flex justify-center">
  <img ngSrc="assets/images/logo-vector.svg" height="161" width="199" alt="Логотип">
</div>
<!-- /Логотип. -->

<!-- Заголовок и описание. -->
<div class="w-full pt-[28px]">
  <div class="w-full">
    <h2 class="w-full text-[28px] font-bold text-center">Наши приложения</h2>
  </div>
  <div
    class="px-[36px] pt-4 text-[20px]">Пожалуйста, выберите приложение, которым хотите воспользоваться.
  </div>
</div>
<!-- /Заголовок и описание. -->

<!-- Кнопки. -->
<div
  class="w-full flex flex-col pt-[28px] gap-[16px]">
  <!-- Кнопка перехода в калькулятор расчёта стоимости доставки. -->
  <button
    (click)="go('/miniapp/calculator')"
    class="button_main">Калькулятор стоимости доставки
  </button>
  <!-- /Кнопка перехода в калькулятор расчёта стоимости доставки. -->

  <!-- Кнопка перехода в интерфейс поиска товаров. -->
  <button
    (click)="go('/miniapp/search')"
    class="button_main">Поиск товаров
  </button>
  <!-- /Кнопка перехода в интерфейс поиска товаров. -->
</div>
<!-- /Кнопки. -->
